import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title} | Collab-Writing.com</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={`${title} | Collab-Writing.com`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${title} | Collab-Writing.com`} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}

export default SEO;