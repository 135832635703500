import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './UserDashboard.css';

function UserDashboard({ user }) {
  const [userStories, setUserStories] = useState([]);

  const fetchUserStories = useCallback(async () => {
    try {
      const response = await fetch(`https://collab-writing.com/api/users/${user.id}/stories`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.ok) {
        const data = await response.json();
        setUserStories(data);
      } else {
        throw new Error('Failed to fetch user stories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [user.id]);

  useEffect(() => {
    fetchUserStories();
  }, [fetchUserStories]);

  return (
    <div className="user-dashboard">
      <h2>Welcome, {user.username}!</h2>
      <h3>Your Stories</h3>
      <ul>
        {userStories.map((story) => (
          <li key={story.id}>
            <Link to={`/write/${story.id}`}>{story.title}</Link>
            <span>Created: {new Date(story.created_at).toLocaleDateString()}</span>
          </li>
        ))}
      </ul>
      <Link to="/create-story" className="create-story-button">Create New Story</Link>
    </div>
  );
}

export default UserDashboard;