import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import ForgotPassword from './components/ForgotPassword';
import StoryList from './components/StoryList';
import StoryCreation from './components/StoryCreation';
import CollaborativeWriting from './components/CollaborativeWriting';
import UserDashboard from './components/UserDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import SEO from './components/SEO';

import './App.css';

function App() {
  const [user, setUser] = useState(null);

  return (
    <Router>
      <SEO 
        title="Collaborative Writing Platform"
        description="Join our community of writers and create amazing stories together!"
        keywords="collaborative writing, storytelling, creative writing, community"
      />
      <div className="App">
        <Navbar user={user} setUser={setUser} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp setUser={setUser} />} />
          <Route path="/signin" element={<SignIn setUser={setUser} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/stories" element={<StoryList />} />
          <Route 
            path="/create-story" 
            element={
              <ProtectedRoute user={user}>
                <StoryCreation user={user} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/write/:storyId" 
            element={
              <ProtectedRoute user={user}>
                <CollaborativeWriting user={user} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute user={user}>
                <UserDashboard user={user} />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;