import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoryCreation.css';

function StoryCreation({ user }) {
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://collab-writing.com/api/stories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ title, user_id: user.id }),
      });
      if (response.ok) {
        const data = await response.json();
        navigate(`/write/${data.story_id}`);
      } else {
        throw new Error('Failed to create story');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="story-creation">
      <h2>Create a New Story</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Story Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        <button type="submit">Create Story</button>
      </form>
    </div>
  );
}

export default StoryCreation;