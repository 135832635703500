import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar({ user, setUser }) {
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/');
  };

  return (
    <nav className="navbar">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/stories">Stories</Link></li>
        {user && <li><Link to="/create-story">Create Story</Link></li>}
        {user && <li><Link to="/dashboard">Dashboard</Link></li>}
        {!user && <li><Link to="/signin">Sign In</Link></li>}
        {!user && <li><Link to="/signup">Sign Up</Link></li>}
        {user && <li><button onClick={handleSignOut}>Sign Out</button></li>}
      </ul>
    </nav>
  );
}

export default Navbar;