import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';

import './Home.css';

function Home() {
  return (
    <>
    <SEO 
        title="Home"
        description="Welcome to Collab-Writing.com - Your collaborative storytelling platform"
        keywords="home, collaborative writing, storytelling"
      />
      
      <div className="home">
      <h1>Welcome to Collaborative Story Writing</h1>
      <p>Join our community of writers and create amazing stories together!</p>
      <Link to="/stories" className="browse-stories">Browse Stories</Link>
    </div>
    </>
   
  );
}

export default Home;