import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StoryList.css';

function StoryList() {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    fetchStories();
  }, []);

  const fetchStories = async () => {
    try {
      const response = await fetch('https://collab-writing.com/api/stories');
      if (response.ok) {
        const data = await response.json();
        setStories(data);
      } else {
        throw new Error('Failed to fetch stories');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="story-list">
      <h2>All Stories</h2>
      <ul>
        {stories.map((story) => (
          <li key={story.id}>
            <Link to={`/write/${story.id}`}>{story.title}</Link>
            <div className="story-info">
              <span>Created by: {story.created_by}</span>
              <span>Last modified by: {story.last_modified_by}</span>
              <span>Created: {new Date(story.created_at).toLocaleDateString()}</span>
              <span>Last modified: {new Date(story.last_modified_at).toLocaleDateString()}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default StoryList;