import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './CollaborativeWriting.css';

function CollaborativeWriting({ user }) {
  const { storyId } = useParams();
  const [story, setStory] = useState(null);
  const [contributions, setContributions] = useState([]);
  const [newContribution, setNewContribution] = useState('');
  const [canContribute, setCanContribute] = useState(true);
  const [wordCount, setWordCount] = useState(0);

  const fetchStoryAndContributions = useCallback(async () => {
    try {
      const response = await fetch(`https://collab-writing.com/api/stories/${storyId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setStory(data.story);
        setContributions(data.contributions);
        setCanContribute(data.contributions.length === 0 || data.contributions[data.contributions.length - 1].author !== user.username);
      } else {
        throw new Error('Failed to fetch story and contributions');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [storyId, user.username]);

  useEffect(() => {
    fetchStoryAndContributions();
  }, [fetchStoryAndContributions]);

  useEffect(() => {
    setWordCount(newContribution.trim().split(/\s+/).length);
  }, [newContribution]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (wordCount > 300) {
      alert('Contribution must be 300 words or less');
      return;
    }
    try {
      const response = await fetch(`https://collab-writing.com/api/stories/${storyId}/contribute`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ content: newContribution }),
      });
      if (response.ok) {
        setNewContribution('');
        fetchStoryAndContributions();
      } else {
        throw new Error('Failed to add contribution');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!story) return <div>Loading...</div>;

  return (
    <div className="collaborative-writing">
      <h2>{story.title}</h2>
      <div className="story-info">
        <p>Created by: {story.created_by}</p>
        <p>Last modified by: {story.last_modified_by}</p>
        <p>Created: {new Date(story.created_at).toLocaleString()}</p>
        <p>Last modified: {new Date(story.last_modified_at).toLocaleString()}</p>
      </div>
      <div className="story-content">
        {contributions.map((contribution, index) => (
          <p key={index}>
            <strong>{contribution.author}:</strong> {contribution.content}
          </p>
        ))}
      </div>
      {canContribute ? (
        <form onSubmit={handleSubmit}>
          <textarea
            value={newContribution}
            onChange={(e) => setNewContribution(e.target.value)}
            placeholder="Add your contribution (max 300 words)"
            required
          />
          <div className="word-count">
            Word count: {wordCount} / 300
          </div>
          <button type="submit" disabled={wordCount > 300}>Submit Contribution</button>
        </form>
      ) : (
        <p>Wait for another user to contribute before you can write again.</p>
      )}
    </div>
  );
}

export default CollaborativeWriting;